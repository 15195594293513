import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { trexApi, trexApiV2 } from '../../services/api';
import { loginRequest } from '../../auth/msalConfig';

import { SessionContextType } from './interface';

const InterceptorContext = createContext<SessionContextType | undefined>(
  undefined
);

export const InterceptorProvider = ({ children }: PropsWithChildren<{}>) => {
  const [token, setToken] = useState<string>('');
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState<boolean>(true);

  const accessTokenRequest = {
    scopes: ['user.read'],
    account: accounts[0],
  };

  const acquireToken = async () => {
    try {
      const { idToken } = await instance.acquireTokenSilent(accessTokenRequest);
      setToken(idToken);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const requestInterceptor = async (request: AxiosRequestConfig) => {
    if (!token) {
      await acquireToken();
    }

    if (request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers['X-Email'] = accounts[0]?.username || '';
    }

    return request;
  };

  const responseInterceptor = async (response: AxiosResponse) => response;

  const errorResponseInterceptor = async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      if (!isAuthenticated) {
        localStorage.clear();
        sessionStorage.clear();

        const logoutRequest = {
          account: instance.getAccountByHomeId(accounts[0].homeAccountId),
        };
        instance.logoutRedirect(logoutRequest);
      }
    }
    return Promise.reject(error);
  };

  useEffect(() => {
    const handleLogin = async () => {
      if (!isAuthenticated) {
        if (inProgress === 'login') {
          toast.warning('Login está em processo!');
        } else if (accounts.length === 0) {
          await instance.handleRedirectPromise();
          instance.loginRedirect(loginRequest);
        }
      }
    };
    handleLogin();
  }, [instance, isAuthenticated]);

  useEffect(() => {
    if (instance && accounts.length > 0) {
      acquireToken();
    }
  }, [instance, accounts]);

  useEffect(() => {
    if (instance && token && isAuthenticated) {
      // Requests
      trexApi.interceptors.request.use(requestInterceptor);
      trexApiV2.interceptors.request.use(requestInterceptor);

      // Responses
      trexApi.interceptors.response.use(
        responseInterceptor,
        errorResponseInterceptor
      );
      trexApiV2.interceptors.response.use(
        responseInterceptor,
        errorResponseInterceptor
      );
    }
  }, [instance, token, isAuthenticated]);

  if (loading) {
    return <></>;
  }

  return (
    <InterceptorContext.Provider value={{ token }}>
      {children}
    </InterceptorContext.Provider>
  );
};

export const useInterceptor = () => {
  const context = useContext(InterceptorContext);
  if (context === undefined) {
    throw new Error(
      'useInterceptor must be used within an InterceptorProvider'
    );
  }
  return context;
};
