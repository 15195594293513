/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Button, Icon, Stack } from '@chakra-ui/react';
import { BsTrashFill } from 'react-icons/bs';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { BiUnlink } from 'react-icons/bi';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as I from '../interfaces';

import AutocompletePartner from '../../../../components/base/autocompletePartner';
import { H3 } from '../../../../components/base/headings';
import usePartners from '../../../../hooks/api/partners';

const Partners = ({
  client,
  isDisabled,
  setPartners,
  partners,
  loadInfo,
}: I.PartnersProps): React.ReactElement => {
  const { register, control } = useFormContext();
  const { deleteBondPartnersClients } = usePartners();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'partners',
  });

  const handleUnlinkPartner = (
    item: { [key: string]: string },
    index: number
  ) => {
    delete item.id;
    const partnerId = Object.values(item).join('');

    if (client?.partners?.includes(partnerId)) {
      deleteBondPartnersClients({
        id: client.id,
        scopeID: partnerId,
        version: client.version,
      })
        .then((res) => {
          if ([200, 201, 204].includes(res.request.status)) {
            toast.success(
              'O cliente foi desvinculado ao parceiro com sucesso!'
            );

            loadInfo!();
          } else {
            const { reason } = JSON.parse(res.request.response);
            toast.warn(
              `Não foi possível desvincular o cliente ao parceiro. ${reason}`
            );
          }
        })
        .catch(() => {
          toast.warn('Não foi possível desvincular o cliente ao parceiro.');
        });
    }

    remove(index);
  };

  return (
    <Stack>
      <H3>
        Parceiro Vinculado
        <Button
          type="button"
          variant="ghost"
          isDisabled={isDisabled}
          fontSize="1.3rem"
          maxW="25px"
          maxH="25px"
          onClick={(): void => append('')}
        >
          <Icon as={MdOutlineAddCircleOutline} />
        </Button>
      </H3>
      {fields.map((item, index) => (
        <Stack
          direction="row"
          spacing="md"
          alignItems="center"
          key={`client_partner_${item.id}`}
        >
          <div className="default--input" style={{ flex: 1 }}>
            <AutocompletePartner
              action={(element: string) => setPartners([...partners, element])}
              disabled={isDisabled}
              {...register(`partner.${index}`)}
              defaultValue={
                client && client.partners ? client.partners[index] : ''
              }
            />
          </div>

          {client && (
            <Button
              type="button"
              isDisabled={isDisabled}
              colorScheme="gray"
              onClick={() => handleUnlinkPartner(item, index)}
            >
              <Icon as={BiUnlink} />
            </Button>
          )}

          {!client && (
            <Button
              type="button"
              isDisabled={isDisabled}
              colorScheme="gray"
              onClick={(): void => remove(index)}
            >
              <Icon as={BsTrashFill} />
            </Button>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default Partners;
