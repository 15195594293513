import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import InformationBase from './baseInformation';
import InputText from '../../../../components/base/v2/inputs/text';
import InputSelect from '../../../../components/base/v2/inputs/select';

import * as I from '../../interfaces';

const SubmenuModel: React.FC<I.SubmenuProps> = ({ isEditing, model }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {isEditing ? (
        <Stack
          data-testid="flow-submenu-edit"
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
          mb="16px"
        >
          <Text fontWeight="bold">Informações do Modelo</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Nome"
              {...register('description')}
              formcontrol={{
                isDisabled: !isEditing,
                isRequired: true,
                error: errors.description,
                style: { width: '50%' },
              }}
            />

            <InputText
              label="URI"
              {...register('external_uri')}
              formcontrol={{
                isDisabled: !isEditing,
                isRequired: true,
                error: errors.external_uri,
                style: { width: '50%' },
              }}
            />

            <InputSelect
              label="Backend"
              {...register('backend')}
              options={[
                { option: 'knative', value: 'knative' },
                { option: 'ai-platform', value: 'ai-platform' },
              ]}
              formcontrol={{
                isDisabled: !isEditing,
                isRequired: true,
                error: errors.backend,
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <InformationBase
          dataTestId="model-submenu"
          identifierPrefix="model"
          model={model}
        />
      )}
    </>
  );
};

export default SubmenuModel;
