import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import DoubleCheckModal from '../../../components/base/v2/doubleCheckModal';
import InputText from '../../../components/base/v2/inputs/text';
import InputSelect from '../../../components/base/v2/inputs/select';
import { useRulesModels } from '../../../providers/models';
import useModels from '../../../hooks/api/models';
import { constants } from '../../../constants';
import { initialValues } from './utils';
import { formatConstants } from '../../../utils/formatConstants';

import { CloneModel } from './interfaces';
import { CloneModalProps } from '../interfaces';

const CloneModal: React.FC<CloneModalProps> = ({ isOpenModal, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clonedObject, setClonedObject] = useState<CloneModel>(initialValues);
  const { isDisabled, handleDisabled, dataContext } = useRulesModels();
  const { push } = useHistory();
  const { createModel } = useModels();

  useEffect(() => {
    setClonedObject({
      description: dataContext.description,
      external_uri: dataContext.external_uri,
      features: dataContext.features,
      backend: dataContext.backend,
    });
  }, []);

  const onClone = async () => {
    setIsLoading(true);
    if (isDisabled) {
      handleDisabled();
    }

    if (!clonedObject) {
      setIsLoading(false);
      onClose();
      return;
    }

    try {
      await createModel(clonedObject).then((res) => {
        const message = formatConstants(constants.cloneWithSuccess, 'Modelo');
        toast.success(message);

        setTimeout(() => {
          push(`/models/info/${res.data.id}`);
          window.location.reload();
        }, 1000);
      });
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(`${constants.errorToSubmitWithReason}: ${err.message}`);
      }
    }
    onClose();
    setIsLoading(false);
  };

  const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClonedObject({ ...clonedObject, description: e.target.value });
  };

  const handleBackendChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setClonedObject({ ...clonedObject, backend: e.target.value });
  };

  return (
    <>
      <DoubleCheckModal
        title="Clonar modelo existente"
        description="Ao clonar o modelo, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia."
        isOpen={isOpenModal}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'v2.brand',
          text: 'Clonar modelo',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do modelo</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Modelo"
              value={clonedObject.description}
              onChange={handleDescription}
              formcontrol={{}}
            />
            <InputSelect
              label="Backend"
              value={clonedObject.backend}
              options={[
                { option: 'Selecione', value: '' },
                { option: 'knative', value: 'knative' },
                { option: 'ai-platform', value: 'ai-platform' },
              ]}
              formcontrol={{}}
              onChange={handleBackendChange}
            />
          </Stack>
        </Stack>
      </DoubleCheckModal>
    </>
  );
};

export default CloneModal;
