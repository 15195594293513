import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { H3 } from '../../../components/base/headings';
import InputRegion from '../../../components/base/v2/inputRegion';
import InputCountry from '../../../components/base/v2/inputCountry';
import { cepMask, documentMask } from '../../../utils/mask';
import InputText from '../../../components/base/v2/inputs/text';
import InputNumber from '../../../components/base/v2/inputs/number';
import IconButton from '../../../components/icons/iconButton';
import copyText from '../../../utils/copyText';
import * as I from './interfaces';
import { FormGroupWithCopyBtn } from './styles';

export default function Data({
  isDisabled,
}: I.ClientDataProps): React.ReactElement {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  const country = useWatch({
    control,
    name: 'address.country',
  });

  const copyName = getValues('name');
  const copyDocument = getValues('document');
  const copyExternalId = getValues('external_id');

  return (
    <Stack direction="column">
      <H3>Dados do cliente</H3>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <FormGroupWithCopyBtn className={copyName && 'hasCopy'}>
          <InputText
            label="Nome"
            {...register('name')}
            formcontrol={{
              isDisabled,
              isRequired: true,
              error: errors.name,
            }}
          />
          {copyName && <IconButton onClick={() => copyText(copyName)} />}
        </FormGroupWithCopyBtn>

        <FormGroupWithCopyBtn className={copyDocument && 'hasCopy'}>
          <InputText
            label="CPF/CNPJ"
            {...register('document')}
            onChange={(e) => setValue('document', documentMask(e.target.value))}
            formcontrol={{
              isDisabled,
              isRequired: true,
              error: errors.document,
            }}
          />
          {copyDocument && (
            <IconButton onClick={() => copyText(copyDocument)} />
          )}
        </FormGroupWithCopyBtn>

        <FormGroupWithCopyBtn className={copyExternalId && 'hasCopy'}>
          <InputText
            label="External ID"
            {...register('external_id')}
            formcontrol={{
              isDisabled,
              error: errors.external_id,
            }}
          />
          {copyExternalId && (
            <IconButton onClick={() => copyText(copyExternalId)} />
          )}
        </FormGroupWithCopyBtn>
      </Stack>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <Controller
          control={control}
          name="address.country"
          render={({ field }) => (
            <InputCountry
              {...field}
              {...register('address.country')}
              label="Pais"
              formcontrol={{
                isDisabled,
                error: errors.country,
                style: { width: '25%' },
              }}
              handleChange={(value: string) => {
                setValue('address.country', value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="address.state"
          render={({ field }) => (
            <InputRegion
              label="Estado"
              {...field}
              {...register('address.state')}
              formcontrol={{
                isDisabled,
                error: errors.state,
                style: { width: '25%' },
              }}
              country={country || ''}
              handleChange={(value: string) => {
                setValue('address.state', value);
              }}
            />
          )}
        />

        <InputText
          label="Cidade"
          {...register('address.city')}
          formcontrol={{
            isDisabled,
            error: errors.city,
            style: { width: '25%' },
          }}
        />
      </Stack>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <InputText
          label="Endereço"
          {...register('address.street')}
          formcontrol={{
            isDisabled,
            error: errors.street,
            style: { width: '25%' },
          }}
        />

        <InputText
          label="CEP"
          {...register('address.postal_code')}
          onChange={(e) =>
            setValue('address.postal_code', cepMask(e.target.value))
          }
          formcontrol={{
            isDisabled,
            error: errors.postal_code,
            style: { width: '25%' },
          }}
        />

        <InputText
          label="Site"
          {...register('website')}
          formcontrol={{
            isDisabled,
            error: errors.website,
            style: { width: '25%' },
          }}
        />
      </Stack>

      <Stack direction="row" spacing="md" style={{ marginTop: '24px' }}>
        <InputNumber
          label="Taxa de aprovação esperada"
          {...register('approval_target')}
          formcontrol={{
            isDisabled,
            error: errors.approval_target,
            style: { width: '25%' },
          }}
        />

        <InputNumber
          label="Taxa de chargeback esperada"
          {...register('chargeback_target')}
          formcontrol={{
            isDisabled,
            error: errors.chargeback_target,
            style: { width: '25%' },
          }}
        />
      </Stack>
    </Stack>
  );
}
